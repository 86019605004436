@mixin button-loading {
  background-image: linear-gradient(
    var(--webiny-theme-color-primary, #66b08b) 0%,
    var(--webiny-theme-color-primary, #66b08b) 100%
  ) !important;
  color: transparent;
}

@mixin button-default {
  border: 1px solid black;
}

@mixin button-primary {
  --typography-color: var(--webiny-theme-color-on-primary, #ffffff);
  background-color: var(--webiny-theme-color-primary, #66b08b);
}

@mixin button-secondary {
  --typography-color: var(--webiny-theme-color-on-secondary, #ffffff);
  background-color: var(--webiny-theme-color-secondary, #5e4b93);
}

@mixin button-red {
  --typography-color: var(--webiny-theme-color-on-red, #ffffff);
  background-color: var(--webiny-theme-color-red, #d8545f);
}

@mixin button-cyan {
  --typography-color: var(--webiny-theme-color-on-cyan, #ffffff);
  background-color: var(--webiny-theme-color-cyan, #5fb6c4);
}

@mixin button-grey {
  --typography-color: var(--webiny-theme-color-on-grey, #ffffff);
  background-color: var(--webiny-theme-color-grey, #f51017);
}

@mixin button-cta {
  --typography-color: var(--webiny-theme-color-on-cta, #ffffff);
  background-color: var(--webiny-theme-color-cta, #f51017);
}

@mixin button-outline-primary {
  --typography-color: var(--webiny-theme-color-primary, #66b08b);
  border: 2px solid var(--webiny-theme-color-primary, #66b08b);
  padding: 10px 16px !important;
  background-color: transparent;
}

@mixin button-outline-secondary {
  --typography-color: var(--webiny-theme-color-secondary, #5e4b93);
  border: 2px solid var(--webiny-theme-color-secondary, #5e4b93);
  padding: 10px 16px !important;
  background-color: transparent;
}

@mixin button-outline-red {
  --typography-color: var(--webiny-theme-color-red, #d8545f);
  border: 2px solid var(--webiny-theme-color-red, #d8545f);
  padding: 10px 16px !important;
  background-color: transparent;
}

@mixin button-outline-cyan {
  --typography-color: var(--webiny-theme-color-cyan, #5fb6c4);
  border: 2px solid var(--webiny-theme-color-cyan, #5fb6c4);
  padding: 10px 16px !important;
  background-color: transparent;
}

@mixin button-outline-grey {
  --typography-color: var(--webiny-theme-color-grey, #cccccc);
  border: 2px solid var(--webiny-theme-color-grey, #cccccc);
  padding: 10px 16px !important;
  background-color: transparent;
}

@mixin button-outline-cta {
  --typography-color: var(--webiny-theme-color-cta, red);
  border: 2px solid var(--webiny-theme-color-cta, red);
  padding: 10px 16px !important;
  background-color: transparent;
}

@mixin button-simple {
  --typography-color: var(--webiny-theme-color-primary, #66b08b);
  background-color: transparent;
  padding: 0 !important;
  min-width: 0;
  &:hover {
    box-shadow: none;
    transform: none;
    text-decoration: underline;
  }
}

@mixin button-variants {
  &--default {
    @include button-default();
  }
  &--primary {
    @include button-primary();
  }
  &--secondary {
    @include button-secondary();
  }
  &--red {
    @include button-red();
  }
  &--cyan {
    @include button-cyan();
  }
  &--grey {
    @include button-grey();
  }
  &--cta {
    @include button-cta();
  }
  &--outline-primary {
    @include button-outline-primary();
  }
  &--outline-secondary {
    @include button-outline-secondary();
  }
  &--outline-red {
    @include button-outline-red();
  }
  &--outline-cyan {
    @include button-outline-cyan();
  }
  &--outline-grey {
    @include button-outline-grey();
  }
  &--outline-cta {
    @include button-outline-cta();
  }
  &--simple {
    @include button-simple();
  }
}

@keyframes webiny-pb-page-element-button-animation-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.webiny-pb-page-element-button {
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 1;
  border-radius: 999px; // Just needs to be a very high value
  will-change: opacity;
  transition: all 0.2s;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.025em;
  font-weight: 600;
  box-sizing: border-box;
  outline: none;
  border: none;
  min-width: 100px;
  width: var(--desktop-width);
  background-color: var(--webiny-theme-color-background, #fafbfb);
  font-family: var(--webiny-theme-typography-primary-font-family, Lexend Deca Light, sans-serif);
  color: var(--typography-color, var(--webiny-theme-color-on-background, #131313));

  &.webiny-pb-base-page-element-style {
    @include variant-variables-vh("padding", 14px, 20px);
    @include variant-variables("justify-content", "center");
    --desktop-margin-bottom: 5px;

    .webiny-pb-typography-body {
      line-height: initial;
      margin: 0;
    }
  }

  &:not(.webiny-pb-base-page-element-style) {
    padding: 14px 20px;
    margin-bottom: 5px;
    max-width: 100%;
  }

  > span.webiny-pb-page-element-button-icon {
    display: block;
    font-size: 1px;
    line-height: 100%;
  }

  &:hover {
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(1px);
  }

  &:disabled,
  &[disabled],
  &.disabled {
    opacity: 0.5;
    transform: translateY(0px);
    box-shadow: none;
    cursor: not-allowed;
  }

  &--loading {
    background-image: linear-gradient(
      var(--webiny-theme-color-primary, #66b08b) 0%,
      var(--webiny-theme-color-primary, #66b08b) 100%
    ) !important;
    color: transparent;
    position: relative;
    text-transform: uppercase;
    font-size: 0;
    height: 42px;
    transition: all 350ms ease;

    &:before {
      margin: -13px 0 0 -13px;
      width: 24px;
      height: 24px;
      position: absolute;
      left: 50%;
      top: 50%;
      color: #fff;
      content: "";
      border-radius: 24px;
      background-clip: padding-box;
      border: rgba(255, 255, 255, 0.25) 2px solid;
      border-top-color: #fff;
      animation: webiny-pb-page-element-button-animation-rotate 750ms linear infinite;
      span {
        color: transparent;
      }
    }
  }

  @include button-variants();

  &__icon {
    display: flex;

    &--left {
      flex-direction: row;
      svg {
        margin-right: 5px;
      }
    }
    &--right {
      flex-direction: row-reverse;
      svg {
        margin-left: 5px;
      }
    }
    &--top {
      flex-direction: column;
      svg {
        margin-bottom: 5px;
      }
    }
    &--bottom {
      flex-direction: column-reverse;
      svg {
        margin-top: 5px;
      }
    }
  }

  // force center align on mobile and when sticky
  @include mobile {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  &-wrapper {
    display: flex;
  }

  // Sticky
  &-sticky {
    background-color: rgba($color: white, $alpha: 0.75);

    &--default {
      background-color: rgba($color: $webiny-pb-theme-text-primary, $alpha: 0.75);
    }
  }

  &-sticky &-wrapper {
    @extend .container;
    padding: 0 20px;
  }

  &-sticky & {
    width: 100%;
    max-width: 920px;
    margin: 10px auto !important;
  }
}

.webiny-pb-media-query {
  &--tablet,
  &--mobile-landscape,
  &--mobile-portrait {
    .webiny-pb-page-element-button {
      background-color: var(--webiny-theme-color-background, white);
      color: var(--typography-color, var(--webiny-theme-color-on-background, #131313));
      border-radius: 999px;

      @include button-variants();
    }
  }
}
