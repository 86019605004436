@mixin mobile {
  @media (min-width: 0px) and (max-width: #{$webiny-pb-theme-tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$webiny-pb-theme-tablet-width}) and (max-width: #{$webiny-pb-theme-desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$webiny-pb-theme-desktop-width}) {
    @content;
  }
}

@mixin desktop-and-tablet {
  @media (min-width: #{$webiny-pb-theme-tablet-width}) {
    @content;
  }
}

@mixin portrait {
  @media (max-width: #{$webiny-pb-theme-mobile-width - 1px}) {
    @content;
  }
}

@mixin landscape {
  @media (min-width: #{$webiny-pb-theme-mobile-width}) {
    @content;
  }
}

@mixin mobile-and-tablet {
  @media (max-width: #{$webiny-pb-theme-desktop-width - 1px}) {
    @content;
  }
}

@mixin variant-variables($property, $value) {
  --desktop-#{$property}: #{$value};
  --tablet-#{$property}: #{$value};
  --mobile-landscape-#{$property}: #{$value};
  --mobile-portrait-#{$property}: #{$value};
}

@mixin variant-variables-trbl($property, $t, $r, $b, $l) {
  @include variant-variables(#{$property}-top, $t);
  @include variant-variables(#{$property}-right, $r);
  @include variant-variables(#{$property}-bottom, $b);
  @include variant-variables(#{$property}-left, $l);
}

@mixin variant-variables-vh($property, $v, $h) {
  @include variant-variables(#{$property}-top, $v);
  @include variant-variables(#{$property}-right, $h);
  @include variant-variables(#{$property}-bottom, $v);
  @include variant-variables(#{$property}-left, $h);
}
