.login-form {
  overflow: hidden;
}

.password-reset-form {
  .form-group:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
