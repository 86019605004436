.modal-dialog .modal-content .container {
  max-width: 100% !important;
}

.modal.loading {
  .modal-body {
    min-height: 300px;

    .loading-text {
      padding-top: 80px;
      text-align: center;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #fff url("/img/lightbox_loading.gif") no-repeat center center;
      opacity: 0.5;
    }
  }
}

.modal-dialog {
  * {
    box-sizing: border-box;
  }

  .modal-header {
    position: relative;

    > button.close {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 50px;
      margin: 0;
      background: darken($brand-primary, 2%);
      color: lighten($brand-primary, 20%);
      opacity: 1;

      @include mobile {
        padding: 0;
      }

      &:hover {
        color: white;
        background: darken($brand-primary, 5%);
      }
    }
  }

  .modal-header.modal-header-transparent {
    background: transparent;
    color: #333;

    > button.close {
      border-top-right-radius: $border-radius-large;
      background: transparent;
      color: lighten(#333, 20%);
      &:hover {
        color: #333;
        background: darken(white, 10%);
      }
    }
  }

  .modal-header.modal-header-flex {
    position: relative;
    display: flex;
    justify-content: stretch;
    align-items: center;

    .modal-title {
      font-weight: bold;
      flex-grow: 1;
      line-height: 1.2em;
    }

    .modal-title-icon {
      font-size: 1.5em;
      margin-right: 15px;
    }

    > button.close {
      position: static;
      margin: 0;
      margin-left: 15px;
      width: auto;
      float: none;
      height: auto;
      &:hover {
        background: transparent;
        color: inherit;
      }
    }
  }

  > button.close,
  > div.close {
    background: #fff;
    border-radius: 50%;
    border: none;
    color: #000;
    cursor: pointer;
    display: table;
    height: 28px;
    line-height: 160%;
    position: absolute;
    right: -8px;
    text-align: center;
    top: -8px;
    vertical-align: middle;
    width: 28px;
    z-index: 2;

    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);

    .glyphicon {
      bottom: auto;
      display: table-cell;
      left: auto;
      position: static;
      right: auto;
      top: auto;
      vertical-align: middle;
    }

    &:hover {
      border-color: lighten($modal-content-border-color, 10%);
      color: lighten($modal-content-border-color, 10%);
    }

    &:focus {
      outline: none !important;
    }
  }
}

@media (max-width: $screen-md) {
  .modal-dialog {
    margin-top: 30px !important;
  }
}

.modal-content {
  border-color: transparent;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.75);
  font-family: sans-serif;

  > .close {
    position: relative;
    z-index: 2;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: sans-serif;
  }

  .button {
    border: none;
    text-decoration: none;
    box-shadow: none;
    background-color: $brand-primary;
    background-image: none;
    background-repeat: no-repeat;
    filter: none;

    &.full-width {
      display: block;
      width: 100%;
    }

    &.disabled {
      background-color: $mibaby-grey;
      cursor: default;
      pointer-events: none;
    }
  }

  .journal-wrapper {
    font-size: 1.4em;
  }

  .lightbox-text {
    ul {
      display: block;
      margin: 0;
      padding: 0;

      li {
        background: url("/img/arrow_list.png") no-repeat left center transparent;
        list-style: none;
        margin: 10px 0;
        padding: 0 0 0 20px;
      }
    }
  }

  .vspace {
    display: inline-block;
    width: 20px;
  }

  select {
    font-size: 14px;
  }

  label.after {
    margin-left: 5px;
  }

  .hidden {
    display: none;
  }

  .margin-top {
    margin-top: 15px;
  }

  .margin-bottom {
    margin-top: 15px;
  }

  .no-margin-bottom {
    margin-bottom: 0;
  }

  .invalid .error {
    bottom: auto;
    left: auto;
    margin: 0 0 8px;
    position: initial;
    right: auto;
    top: auto;
  }

  .form-control {
    margin: 0;
  }

  .form-group {
    margin: 0 0 15px 0;
  }

  .form-group + .error {
    margin-top: -13px;
  }

  .form-control + .error {
    margin-top: 2px;
  }
}

.modal-header {
  color: #fff;
  background: var(--webiny-theme-color-primary, $mibaby-primary);
  padding: $modal-inner-padding-xs;

  &.bg-secondary {
    background: var(--webiny-theme-color-secondary, $mibaby-secondary);
  }

  &.bg-cta {
    background: var(--webiny-theme-color-cta, $mibaby-cta);
  }

  @media (min-width: $screen-sm-min) {
    padding: $modal-inner-padding;
  }
}

.modal-title {
  font-family: Open Sans, Arial, sans-serif !important;
  font-size: 16px;
  font-weight: normal;
}

.modal-body {
  padding: $modal-inner-padding-xs;

  @media (min-width: $screen-sm-min) {
    padding: $modal-inner-padding;
  }
}

.modal-footer {
  background-color: #ededed;
}

.modal-body-floating-close {
  z-index: 9999;
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  opacity: 0.5;

  .close {
    padding: 20px !important;
    opacity: 1;

    @media (max-width: $screen-sm-max) {
      padding: 10px !important;
    }
  }
}

.modal-content-condensed {
  .modal-body + .modal-body,
  .modal-body + .modal-heading,
  .modal-heading + .modal-body,
  .modal-body + * > .modal-body:first-child {
    padding-top: 0;
  }
}

@media (max-width: $screen-sm-max) {
  .modal-mobile-full,
  .modal-mobile-fullscreen {
    .modal-dialog {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin-top: 10px !important;
      width: auto;

      .modal-content {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .modal-header {
          position: relative;
          padding-right: 10px;
        }

        .close {
          font-size: 35px;
          font-weight: normal;
          padding: 0 0 10px 10px;
          text-shadow: none;
        }

        .modal-body-overflow-x {
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
          flex-grow: 1;
        }

        .modal-footer {
          height: 90px;
        }
      }
    }
  }

  .modal-mobile-fullscreen {
    .modal-dialog {
      margin: 0 !important;

      .modal-content {
        border-radius: 0;
        border-width: 0;
      }
    }
  }
}
