.modal-header {
  background: $webiny-pb-theme-primary;
  color: $webiny-pb-theme-on-primary;

  button.close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 13px;
    border: 0;
    background: transparent;
    color: $webiny-pb-theme-on-primary;
    font-size: 1.7em;
    cursor: pointer;

    .sr-only {
      display: none;
    }
  }
}
