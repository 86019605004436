.comments {
  .modal-dialog &.list-group {
    margin-bottom: 0;

    .list-group-item {
      border-radius: 0;
    }
  }

  .comment {
    margin: 0;

    &:not(:last-child) {
      border-bottom: 0;
    }
  }

  .comment.old + .comment.old {
    border-top: 0;
    padding-top: 5px;
  }

  &.list-group.comments + &.list-group.comments-list {
    margin-top: -20px;

    @media(max-width: $screen-sm-max) {
      margin-top: -5px;
    }
  }

  .list-group + & > .comment:first-child {
    border-top: 0;
  }

  .load,
  .loading {
    text-align: center;
    border-top: 0;
  }
}

.comment {
  display: flex !important;

  .author {
    flex: 0 0 60px;
    margin: 5px 10px 0 0;
    overflow: hidden;
    text-align: center;
  }

  .body {
    flex-grow: 1;
  }

  .content {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px 12px;
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 18px;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-left: 0;
      margin-top: -10px;
      margin-left: -10px;
    }

    &:before {
      left: 0;
      border-right-color: #ccc;
    }

    &:after {
      left: 1px;
      border-right-color: white;
    }

    & > :last-child {
      margin-bottom: 0;
    }

    .img-thumbnail {
      max-height: 400px;
    }
  }

  &.new .content {
    cursor: text;

    &:hover {
      border-color: #9ba720;

      &:before {
        border-right-color: #9ba720;
      }
    }
  }

  &.old .content {
    display: inline-block;
    background: #ebecf0;

    &:after {
      border-right-color: #ebecf0;
    }
  }

  .editor .toolbar {
    justify-content: flex-end;
  }

  .footer {
    margin-top: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;

    .btn {
      border: 0;
      padding: 0;
      font-size: inherit;
      background: inherit;
      font-weight: inherit;
      color: inherit;

      i {
        margin-right: 4px;
      }
    }

    .actions {
      flex-grow: 1;
      text-align: right;

      span {
        margin-left: 8px;
        cursor: pointer;

        i {
          margin-right: 5px;
        }
      }
    }
  }
}
