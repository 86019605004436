/*
 * Mb icons
 */

$icomoon-font-family: "miBaby" !default;
$icomoon-font-path: "/fonts" !default;

$mbicon-tragehilfe: "\e600";
$mbicon-rucksack: "\e601";
$mbicon-babysitz: "\e602";
$mbicon-kinderwagen: "\e603";
$mbicon-bett: "\e604";
$mbicon-strampler: "\e605";
$mbicon-matratze: "\e606";
$mbicon-schnuller: "\e607";
$mbicon-schlafen: "\e608";
$mbicon-stillen: "\e609";
$mbicon-flaeschen: "\e60a";
$mbicon-textilien: "\e60b";
$mbicon-hochsitz: "\e60c";
$mbicon-waesche: "\e60d";
$mbicon-kleidung: "\e60e";
$mbicon-outdoor: "\e60f";
$mbicon-stillmode: "\e610";
$mbicon-sonstiges: "\e611";
$mbicon-windeln: "\e612";
$mbicon-bad: "\e613";
$mbicon-haushalt: "\e614";
$mbicon-pflege: "\e615";
$mbicon-spielen: "\e616";
$mbicon-bewegung: "\e617";
$mbicon-spielzeug: "\e618";
$mbicon-musik: "\e619";
$mbicon-schenken: "\e61a";
$mbicon-moebel: "\e61b";
$mbicon-wohnen: "\e61c";
$mbicon-accessoires: "\e61d";
$mbicon-essen: "\e61e";
$mbicon-mode: "\e61f";
$mbicon-produkte: "\e620";
$mbicon-themen: "\e621";
$mbicon-login: "\e622";
$mbicon-suche: "\e623";
$mbicon-pfeil: "\e900";
$mbicon-wohlempfinden: "\e901";
$mbicon-checkliste: "\e902";
$mbicon-inspiration: "\e903";
$mbicon-percent-badge: "\e904";
$mbicon-aktionen: "\e905";
$mbicon-trophy: "\e906";
$mbicon-badge: "\e907";
$mbicon-amazon: "\e908";
$mbicon-star-empty: "\e909";
$mbicon-quote-down: "\e90a";
$mbicon-quote-up: "\e90b";
$mbicon-specialist: "\e90c";
$mbicon-star-filled: "\e90d";
$mbicon-check: "\e90e";
$mbicon-cross: "\e90f";
$mbicon-amazon-badge: "\e910";
$mbicon-trophy-badge: "\e911";
$mbicon-close: "\e912";
$mbicon-box-badge: "\e913";
$mbicon-winner-badge: "\e914";
$mbicon-euro-badge: "\e915";
$mbicon-mail-badge: "\e916";
$mbicon-community-badge: "\e917";
$mbicon-info-badge: "\e918";
$mbicon-procon-badge: "\e919";
$mbicon-question-badge: "\e91a";
$mbicon-review-badge: "\e91b";
$mbicon-mic: "\e91c";
$mbicon-anonym: "\e91d";
$mbicon-baby: "\e91e";
$mbicon-community: "\e91f";
$mbicon-daumen-hoch: "\e920";
$mbicon-exklusiv: "\e921";
$mbicon-facebook: "\e922";
$mbicon-gewinnspiel: "\e923";
$mbicon-gluehbirne: "\e924";
$mbicon-herz: "\e925";
$mbicon-herz-baby: "\e926";
$mbicon-instagram: "\e927";
$mbicon-klicken: "\e928";
$mbicon-liste: "\e929";
$mbicon-paket: "\e92a";
$mbicon-podcast: "\e92b";
$mbicon-sale: "\e92c";
$mbicon-schwanger: "\e92d";
$mbicon-shopping: "\e92e";
$mbicon-sprechblase: "\e92f";
$mbicon-tiktok: "\e930";
$mbicon-user-in: "\e931";
$mbicon-video: "\e932";
$mbicon-youtube: "\e933";
$mbicon-baby-kreis: "\e934";
$mbicon-herz-kreis: "\e935";
$mbicon-sale-kreis: "\e936";
$mbicon-klick-kreis: "\e937";
$mbicon-liste-kreis: "\e938";
$mbicon-paket-kreis: "\e939";
$mbicon-video-kreis: "\e93a";
$mbicon-anonym-kreis: "\e93b";
$mbicon-tiktok-kreis: "\e93c";
$mbicon-podcast-kreis: "\e93d";
$mbicon-user-in-kreis: "\e93e";
$mbicon-youtube-kreis: "\e93f";
$mbicon-exklusiv-kreis: "\e940";
$mbicon-facebook-kreis: "\e941";
$mbicon-shopping-kreis: "\e942";
$mbicon-baby-herz-kreis: "\e943";
$mbicon-community-kreis: "\e944";
$mbicon-instagram-kreis: "\e945";
$mbicon-schwanger-kreis: "\e946";
$mbicon-gluehbirne-kreis: "\e947";
$mbicon-daumen-hoch-kreis: "\e948";
$mbicon-gewinnspiel-kreis: "\e949";
$mbicon-sprechblase-kreis: "\e94a";
$mbicon-baby-flaeche-path1: "\e94b";
$mbicon-baby-flaeche-path2: "\e94c";
$mbicon-herz-flaeche-path1: "\e94d";
$mbicon-herz-flaeche-path2: "\e94e";
$mbicon-sale-flaeche-path1: "\e94f";
$mbicon-sale-flaeche-path2: "\e950";
$mbicon-klick-flaeche-path1: "\e951";
$mbicon-klick-flaeche-path2: "\e952";
$mbicon-liste-flaeche-path1: "\e953";
$mbicon-liste-flaeche-path2: "\e954";
$mbicon-paket-flaeche-path1: "\e955";
$mbicon-paket-flaeche-path2: "\e956";
$mbicon-video-flaeche-path1: "\e957";
$mbicon-video-flaeche-path2: "\e958";
$mbicon-anonym-flaeche-path1: "\e959";
$mbicon-anonym-flaeche-path2: "\e95a";
$mbicon-tiktok-flaeche-path1: "\e95b";
$mbicon-tiktok-flaeche-path2: "\e95c";
$mbicon-podcast-flaeche-path1: "\e95d";
$mbicon-podcast-flaeche-path2: "\e95e";
$mbicon-user-in-flaeche-path1: "\e95f";
$mbicon-user-in-flaeche-path2: "\e960";
$mbicon-youtube-flaeche-path1: "\e961";
$mbicon-youtube-flaeche-path2: "\e962";
$mbicon-exklusiv-flaeche-path1: "\e963";
$mbicon-exklusiv-flaeche-path2: "\e964";
$mbicon-facebook-flaeche-path1: "\e965";
$mbicon-facebook-flaeche-path2: "\e966";
$mbicon-shopping-flaeche-path1: "\e967";
$mbicon-shopping-flaeche-path2: "\e968";
$mbicon-baby-herz-flaeche-path1: "\e969";
$mbicon-baby-herz-flaeche-path2: "\e96a";
$mbicon-community-flaeche-path1: "\e96b";
$mbicon-community-flaeche-path2: "\e96c";
$mbicon-instagram-flaeche-path1: "\e96d";
$mbicon-instagram-flaeche-path2: "\e96e";
$mbicon-schwanger-flaeche-path1: "\e96f";
$mbicon-schwanger-flaeche-path2: "\e970";
$mbicon-gluehbirne-flaeche-path1: "\e971";
$mbicon-gluehbirne-flaeche-path2: "\e972";
$mbicon-daumen-hoch-flaeche-path1: "\e973";
$mbicon-daumen-hoch-flaeche-path2: "\e974";
$mbicon-gewinnspiel-flaeche-path1: "\e975";
$mbicon-gewinnspiel-flaeche-path2: "\e976";
$mbicon-sprechblase-flaeche-path1: "\e977";
$mbicon-sprechblase-flaeche-path2: "\e978";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?n8mc7j') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?n8mc7j') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?n8mc7j##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.mbicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mbicon-tragehilfe {
  &:before {
    content: $mbicon-tragehilfe;
  }
}
.mbicon-rucksack {
  &:before {
    content: $mbicon-rucksack;
  }
}
.mbicon-babysitz {
  &:before {
    content: $mbicon-babysitz;
  }
}
.mbicon-kinderwagen {
  &:before {
    content: $mbicon-kinderwagen;
  }
}
.mbicon-bett {
  &:before {
    content: $mbicon-bett;
  }
}
.mbicon-strampler {
  &:before {
    content: $mbicon-strampler;
  }
}
.mbicon-matratze {
  &:before {
    content: $mbicon-matratze;
  }
}
.mbicon-schnuller {
  &:before {
    content: $mbicon-schnuller;
  }
}
.mbicon-schlafen {
  &:before {
    content: $mbicon-schlafen;
  }
}
.mbicon-stillen {
  &:before {
    content: $mbicon-stillen;
  }
}
.mbicon-flaeschen {
  &:before {
    content: $mbicon-flaeschen;
  }
}
.mbicon-textilien {
  &:before {
    content: $mbicon-textilien;
  }
}
.mbicon-hochsitz {
  &:before {
    content: $mbicon-hochsitz;
  }
}
.mbicon-waesche {
  &:before {
    content: $mbicon-waesche;
  }
}
.mbicon-kleidung {
  &:before {
    content: $mbicon-kleidung;
  }
}
.mbicon-outdoor {
  &:before {
    content: $mbicon-outdoor;
  }
}
.mbicon-stillmode {
  &:before {
    content: $mbicon-stillmode;
  }
}
.mbicon-sonstiges {
  &:before {
    content: $mbicon-sonstiges;
  }
}
.mbicon-windeln {
  &:before {
    content: $mbicon-windeln;
  }
}
.mbicon-bad {
  &:before {
    content: $mbicon-bad;
  }
}
.mbicon-haushalt {
  &:before {
    content: $mbicon-haushalt;
  }
}
.mbicon-pflege {
  &:before {
    content: $mbicon-pflege;
  }
}
.mbicon-spielen {
  &:before {
    content: $mbicon-spielen;
  }
}
.mbicon-bewegung {
  &:before {
    content: $mbicon-bewegung;
  }
}
.mbicon-spielzeug {
  &:before {
    content: $mbicon-spielzeug;
  }
}
.mbicon-musik {
  &:before {
    content: $mbicon-musik;
  }
}
.mbicon-schenken {
  &:before {
    content: $mbicon-schenken;
  }
}
.mbicon-moebel {
  &:before {
    content: $mbicon-moebel;
  }
}
.mbicon-wohnen {
  &:before {
    content: $mbicon-wohnen;
  }
}
.mbicon-accessoires {
  &:before {
    content: $mbicon-accessoires;
  }
}
.mbicon-essen {
  &:before {
    content: $mbicon-essen;
  }
}
.mbicon-mode {
  &:before {
    content: $mbicon-mode;
  }
}
.mbicon-produkte {
  &:before {
    content: $mbicon-produkte;
  }
}
.mbicon-themen {
  &:before {
    content: $mbicon-themen;
  }
}
.mbicon-login {
  &:before {
    content: $mbicon-login;
  }
}
.mbicon-suche {
  &:before {
    content: $mbicon-suche;
  }
}
.mbicon-pfeil {
  &:before {
    content: $mbicon-pfeil;
  }
}
.mbicon-wohlempfinden {
  &:before {
    content: $mbicon-wohlempfinden;
  }
}
.mbicon-checkliste {
  &:before {
    content: $mbicon-checkliste;
  }
}
.mbicon-inspiration {
  &:before {
    content: $mbicon-inspiration;
  }
}
.mbicon-percent-badge {
  &:before {
    content: $mbicon-percent-badge;
  }
}
.mbicon-aktionen {
  &:before {
    content: $mbicon-aktionen;
  }
}
.mbicon-trophy {
  &:before {
    content: $mbicon-trophy;
  }
}
.mbicon-badge {
  &:before {
    content: $mbicon-badge;
  }
}
.mbicon-amazon {
  &:before {
    content: $mbicon-amazon;
  }
}
.mbicon-star-empty {
  &:before {
    content: $mbicon-star-empty;
  }
}
.mbicon-quote-down {
  &:before {
    content: $mbicon-quote-down;
  }
}
.mbicon-quote-up {
  &:before {
    content: $mbicon-quote-up;
  }
}
.mbicon-specialist {
  &:before {
    content: $mbicon-specialist;
  }
}
.mbicon-star-filled {
  &:before {
    content: $mbicon-star-filled;
  }
}
.mbicon-check {
  &:before {
    content: $mbicon-check;
  }
}
.mbicon-cross {
  &:before {
    content: $mbicon-cross;
  }
}
.mbicon-amazon-badge {
  &:before {
    content: $mbicon-amazon-badge;
  }
}
.mbicon-trophy-badge {
  &:before {
    content: $mbicon-trophy-badge;
  }
}
.mbicon-close {
  &:before {
    content: $mbicon-close;
  }
}
.mbicon-box-badge {
  &:before {
    content: $mbicon-box-badge;
  }
}
.mbicon-winner-badge {
  &:before {
    content: $mbicon-winner-badge;
  }
}
.mbicon-euro-badge {
  &:before {
    content: $mbicon-euro-badge;
  }
}
.mbicon-mail-badge {
  &:before {
    content: $mbicon-mail-badge;
  }
}
.mbicon-community-badge {
  &:before {
    content: $mbicon-community-badge;
  }
}
.mbicon-info-badge {
  &:before {
    content: $mbicon-info-badge;
  }
}
.mbicon-procon-badge {
  &:before {
    content: $mbicon-procon-badge;
  }
}
.mbicon-question-badge {
  &:before {
    content: $mbicon-question-badge;
  }
}
.mbicon-review-badge {
  &:before {
    content: $mbicon-review-badge;
  }
}
.mbicon-mic {
  &:before {
    content: $mbicon-mic;
  }
}
.mbicon-anonym {
  &:before {
    content: $mbicon-anonym;
  }
}
.mbicon-baby {
  &:before {
    content: $mbicon-baby;
  }
}
.mbicon-community {
  &:before {
    content: $mbicon-community;
  }
}
.mbicon-daumen-hoch {
  &:before {
    content: $mbicon-daumen-hoch;
  }
}
.mbicon-exklusiv {
  &:before {
    content: $mbicon-exklusiv;
  }
}
.mbicon-facebook {
  &:before {
    content: $mbicon-facebook;
  }
}
.mbicon-gewinnspiel {
  &:before {
    content: $mbicon-gewinnspiel;
  }
}
.mbicon-gluehbirne {
  &:before {
    content: $mbicon-gluehbirne;
  }
}
.mbicon-herz {
  &:before {
    content: $mbicon-herz;
  }
}
.mbicon-herz-baby {
  &:before {
    content: $mbicon-herz-baby;
  }
}
.mbicon-instagram {
  &:before {
    content: $mbicon-instagram;
  }
}
.mbicon-klicken {
  &:before {
    content: $mbicon-klicken;
  }
}
.mbicon-liste {
  &:before {
    content: $mbicon-liste;
  }
}
.mbicon-paket {
  &:before {
    content: $mbicon-paket;
  }
}
.mbicon-podcast {
  &:before {
    content: $mbicon-podcast;
  }
}
.mbicon-sale {
  &:before {
    content: $mbicon-sale;
  }
}
.mbicon-schwanger {
  &:before {
    content: $mbicon-schwanger;
  }
}
.mbicon-shopping {
  &:before {
    content: $mbicon-shopping;
  }
}
.mbicon-sprechblase {
  &:before {
    content: $mbicon-sprechblase;
  }
}
.mbicon-tiktok {
  &:before {
    content: $mbicon-tiktok;
  }
}
.mbicon-user-in {
  &:before {
    content: $mbicon-user-in;
  }
}
.mbicon-video {
  &:before {
    content: $mbicon-video;
  }
}
.mbicon-youtube {
  &:before {
    content: $mbicon-youtube;
  }
}
.mbicon-baby-kreis {
  &:before {
    content: $mbicon-baby-kreis;
  }
}
.mbicon-herz-kreis {
  &:before {
    content: $mbicon-herz-kreis;
  }
}
.mbicon-sale-kreis {
  &:before {
    content: $mbicon-sale-kreis;
  }
}
.mbicon-klick-kreis {
  &:before {
    content: $mbicon-klick-kreis;
  }
}
.mbicon-liste-kreis {
  &:before {
    content: $mbicon-liste-kreis;
  }
}
.mbicon-paket-kreis {
  &:before {
    content: $mbicon-paket-kreis;
  }
}
.mbicon-video-kreis {
  &:before {
    content: $mbicon-video-kreis;
  }
}
.mbicon-anonym-kreis {
  &:before {
    content: $mbicon-anonym-kreis;
  }
}
.mbicon-tiktok-kreis {
  &:before {
    content: $mbicon-tiktok-kreis;
  }
}
.mbicon-podcast-kreis {
  &:before {
    content: $mbicon-podcast-kreis;
  }
}
.mbicon-user-in-kreis {
  &:before {
    content: $mbicon-user-in-kreis;
  }
}
.mbicon-youtube-kreis {
  &:before {
    content: $mbicon-youtube-kreis;
  }
}
.mbicon-exklusiv-kreis {
  &:before {
    content: $mbicon-exklusiv-kreis;
  }
}
.mbicon-facebook-kreis {
  &:before {
    content: $mbicon-facebook-kreis;
  }
}
.mbicon-shopping-kreis {
  &:before {
    content: $mbicon-shopping-kreis;
  }
}
.mbicon-baby-herz-kreis {
  &:before {
    content: $mbicon-baby-herz-kreis;
  }
}
.mbicon-community-kreis {
  &:before {
    content: $mbicon-community-kreis;
  }
}
.mbicon-instagram-kreis {
  &:before {
    content: $mbicon-instagram-kreis;
  }
}
.mbicon-schwanger-kreis {
  &:before {
    content: $mbicon-schwanger-kreis;
  }
}
.mbicon-gluehbirne-kreis {
  &:before {
    content: $mbicon-gluehbirne-kreis;
  }
}
.mbicon-daumen-hoch-kreis {
  &:before {
    content: $mbicon-daumen-hoch-kreis;
  }
}
.mbicon-gewinnspiel-kreis {
  &:before {
    content: $mbicon-gewinnspiel-kreis;
  }
}
.mbicon-sprechblase-kreis {
  &:before {
    content: $mbicon-sprechblase-kreis;
  }
}
.mbicon-baby-flaeche .path1 {
  &:before {
    content: $mbicon-baby-flaeche-path1;
  }
}
.mbicon-baby-flaeche .path2 {
  &:before {
    content: $mbicon-baby-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-herz-flaeche .path1 {
  &:before {
    content: $mbicon-herz-flaeche-path1;
  }
}
.mbicon-herz-flaeche .path2 {
  &:before {
    content: $mbicon-herz-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-sale-flaeche .path1 {
  &:before {
    content: $mbicon-sale-flaeche-path1;
  }
}
.mbicon-sale-flaeche .path2 {
  &:before {
    content: $mbicon-sale-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-klick-flaeche .path1 {
  &:before {
    content: $mbicon-klick-flaeche-path1;
  }
}
.mbicon-klick-flaeche .path2 {
  &:before {
    content: $mbicon-klick-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-liste-flaeche .path1 {
  &:before {
    content: $mbicon-liste-flaeche-path1;
  }
}
.mbicon-liste-flaeche .path2 {
  &:before {
    content: $mbicon-liste-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-paket-flaeche .path1 {
  &:before {
    content: $mbicon-paket-flaeche-path1;
  }
}
.mbicon-paket-flaeche .path2 {
  &:before {
    content: $mbicon-paket-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-video-flaeche .path1 {
  &:before {
    content: $mbicon-video-flaeche-path1;
  }
}
.mbicon-video-flaeche .path2 {
  &:before {
    content: $mbicon-video-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-anonym-flaeche .path1 {
  &:before {
    content: $mbicon-anonym-flaeche-path1;
  }
}
.mbicon-anonym-flaeche .path2 {
  &:before {
    content: $mbicon-anonym-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-tiktok-flaeche .path1 {
  &:before {
    content: $mbicon-tiktok-flaeche-path1;
  }
}
.mbicon-tiktok-flaeche .path2 {
  &:before {
    content: $mbicon-tiktok-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-podcast-flaeche .path1 {
  &:before {
    content: $mbicon-podcast-flaeche-path1;
  }
}
.mbicon-podcast-flaeche .path2 {
  &:before {
    content: $mbicon-podcast-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-user-in-flaeche .path1 {
  &:before {
    content: $mbicon-user-in-flaeche-path1;
  }
}
.mbicon-user-in-flaeche .path2 {
  &:before {
    content: $mbicon-user-in-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-youtube-flaeche .path1 {
  &:before {
    content: $mbicon-youtube-flaeche-path1;
  }
}
.mbicon-youtube-flaeche .path2 {
  &:before {
    content: $mbicon-youtube-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-exklusiv-flaeche .path1 {
  &:before {
    content: $mbicon-exklusiv-flaeche-path1;
  }
}
.mbicon-exklusiv-flaeche .path2 {
  &:before {
    content: $mbicon-exklusiv-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-facebook-flaeche .path1 {
  &:before {
    content: $mbicon-facebook-flaeche-path1;
  }
}
.mbicon-facebook-flaeche .path2 {
  &:before {
    content: $mbicon-facebook-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-shopping-flaeche .path1 {
  &:before {
    content: $mbicon-shopping-flaeche-path1;
  }
}
.mbicon-shopping-flaeche .path2 {
  &:before {
    content: $mbicon-shopping-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-baby-herz-flaeche .path1 {
  &:before {
    content: $mbicon-baby-herz-flaeche-path1;
  }
}
.mbicon-baby-herz-flaeche .path2 {
  &:before {
    content: $mbicon-baby-herz-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-community-flaeche .path1 {
  &:before {
    content: $mbicon-community-flaeche-path1;
  }
}
.mbicon-community-flaeche .path2 {
  &:before {
    content: $mbicon-community-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-instagram-flaeche .path1 {
  &:before {
    content: $mbicon-instagram-flaeche-path1;
  }
}
.mbicon-instagram-flaeche .path2 {
  &:before {
    content: $mbicon-instagram-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-schwanger-flaeche .path1 {
  &:before {
    content: $mbicon-schwanger-flaeche-path1;
  }
}
.mbicon-schwanger-flaeche .path2 {
  &:before {
    content: $mbicon-schwanger-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-gluehbirne-flaeche .path1 {
  &:before {
    content: $mbicon-gluehbirne-flaeche-path1;
  }
}
.mbicon-gluehbirne-flaeche .path2 {
  &:before {
    content: $mbicon-gluehbirne-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-daumen-hoch-flaeche .path1 {
  &:before {
    content: $mbicon-daumen-hoch-flaeche-path1;
  }
}
.mbicon-daumen-hoch-flaeche .path2 {
  &:before {
    content: $mbicon-daumen-hoch-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-gewinnspiel-flaeche .path1 {
  &:before {
    content: $mbicon-gewinnspiel-flaeche-path1;
  }
}
.mbicon-gewinnspiel-flaeche .path2 {
  &:before {
    content: $mbicon-gewinnspiel-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}
.mbicon-sprechblase-flaeche .path1 {
  &:before {
    content: $mbicon-sprechblase-flaeche-path1;
  }
}
.mbicon-sprechblase-flaeche .path2 {
  &:before {
    content: $mbicon-sprechblase-flaeche-path2;
    margin-left: -1em;
    color: white
  }
}

// legacy
.mbicon-sparwelt {
  @extend .mbicon-percent-badge;
}
.mbicon-testsieger {
  @extend .mbicon-winner-badge;
}

/*
 * Reference icons
 */

.refIcon {
  background: transparent url("/img/frontpage_not_logged_in/reference_icon_sprite.png") no-repeat;
  display: block;
  text-indent: -9999px;
  height: 19px;

  &.eltern_de {
    background-position: 0px 0px;
    width: 71px;
  }

  &.hebammen_zeitschrift {
    background-position: -113px 0px;
    width: 76px;
  }

  &.sueddeutsche_de {
    background-position: -230px 0px;
    width: 118px;
  }

  &.rtl {
    background-position: -390px 0px;
    width: 65px;
  }

  &.pro_sieben {
    background-position: -497px 0px;
    width: 20px;
  }

  &.sat_1 {
    background-position: -560px 0px;
    width: 60px;
  }
}
