[class*="webiny-pb-page-"],
[class*="webiny-pb-section-"] {
  * {
    box-sizing: border-box;
  }
}

.webiny-pb-page {
  min-height: 100vh;
  position: relative;
}
/**
 * PB editor responsive device preview style.
 * We dynamically assign these to PB page document element in editor,
 * based on selected mode.
 */
.webiny-pb-editor-device--desktop {
  max-width: 100%;
}
.webiny-pb-editor-device--tablet {
  max-width: 768px;
}
.webiny-pb-editor-device--mobile-landscape {
  max-width: 568px;
}
.webiny-pb-editor-device--mobile-portrait {
  max-width: 320px;
}
/**
 * Responsive page root CSS class.
 * We dynamically assign these to PB page document element,
 * based on screen size to mimic "@media-query" functionality.
 */
.webiny-pb-media-query--desktop {
}
.webiny-pb-media-query--tablet {
}
.webiny-pb-media-query--mobile-landscape {
}
.webiny-pb-media-query--mobile-portrait {
}

.webiny-pb-section-content {
  background-color: var(--webiny-theme-color-surface);
  overflow: hidden;
  line-height: 1.625;
}

.webiny-pb-page-document {
  background-color: var(--webiny-theme-color-surface, #fff);
  max-width: 100%;
  line-height: 1.625;

  // fixes the overflow created by the animation library (fix pending)
  // https://github.com/michalsnik/aos/issues/416
  overflow: hidden;
  width: 100vw;
}
.webiny-pb-media-query--desktop .webiny-pb-page-document,
.webiny-pb-media-query--tablet .webiny-pb-page-document {
  min-height: calc(100vh - 230px);
}
.webiny-pb-media-query--mobile-landscape .webiny-pb-page-document,
.webiny-pb-media-query--mobile-portrait .webiny-pb-page-document {
  min-height: calc(100vh - 230px);
}

// base css class that applies to all elements created by the page editor
.webiny-pb-base-page-element-style {
  box-sizing: border-box;
  max-width: min(var(--desktop-width), 100%);
  height: var(--desktop-height);

  padding: var(--desktop-padding-top) var(--desktop-padding-right) var(--desktop-padding-bottom)
    var(--desktop-padding-left);
  margin: var(--desktop-margin-top) var(--desktop-margin-right) var(--desktop-margin-bottom)
    var(--desktop-margin-left);

  @include border(--desktop);
  @include background(--desktop);

  color: var(--desktop-color);
  // text-align: var(--desktop-text-align);

  justify-content: var(--desktop-justify-content);
  align-items: var(--desktop-align-items);
}
// Some styles need more specificity
.webiny-pb-media-query--desktop .webiny-pb-base-page-element-style {
}

.webiny-pb-media-query--tablet .webiny-pb-base-page-element-style {
  max-width: min(var(--tablet-width), 100%);
  height: var(--tablet-height);

  padding: var(--tablet-padding-top) var(--tablet-padding-right) var(--tablet-padding-bottom)
    var(--tablet-padding-left);
  margin: var(--tablet-margin-top) var(--tablet-margin-right) var(--tablet-margin-bottom)
    var(--tablet-margin-left);

  @include border(--tablet);
  @include background(--tablet);

  color: var(--tablet-color);
  // text-align: var(--tablet-text-align);

  justify-content: var(--tablet-justify-content);
  align-items: var(--tablet-align-items);
}
.webiny-pb-media-query--mobile-landscape .webiny-pb-base-page-element-style {
  max-width: min(var(--mobile-landscape-width), 100%);
  height: var(--mobile-landscape-height);

  padding: var(--mobile-landscape-padding-top) var(--mobile-landscape-padding-right)
    var(--mobile-landscape-padding-bottom) var(--mobile-landscape-padding-left);
  margin: var(--mobile-landscape-margin-top) var(--mobile-landscape-margin-right)
    var(--mobile-landscape-margin-bottom) var(--mobile-landscape-margin-left);

  @include border(--mobile-landscape);
  @include background(--mobile-landscape);

  color: var(--mobile-landscape-color);
  // text-align: var(--mobile-landscape-text-align);

  justify-content: var(--mobile-landscape-justify-content);
  align-items: var(--mobile-landscape-align-items);
}
.webiny-pb-media-query--mobile-portrait .webiny-pb-base-page-element-style {
  max-width: min(var(--mobile-portrait-width), 100%);
  height: var(--mobile-portrait-height);

  padding: var(--mobile-portrait-padding-top) var(--mobile-portrait-padding-right)
    var(--mobile-portrait-padding-bottom) var(--mobile-portrait-padding-left);
  margin: var(--mobile-portrait-margin-top) var(--mobile-portrait-margin-right)
    var(--mobile-portrait-margin-bottom) var(--mobile-portrait-margin-left);

  @include border(--mobile-portrait);
  @include background(--mobile-portrait);

  color: var(--mobile-portrait-color);
  // text-align: var(--mobile-portrait-text-align);

  justify-content: var(--mobile-portrait-justify-content);
  align-items: var(--mobile-portrait-align-items);
}
// Hide content on mobile
.webiny-pb-media-query--mobile-landscape .hide-on-mobile,
.webiny-pb-media-query--mobile-portrait .hide-on-mobile {
  display: none;
}
// Hide content on desktop & tablet
.webiny-pb-media-query--desktop .hide-on-desktop-and-tablet,
.webiny-pb-media-query--tablet .hide-on-desktop-and-tablet {
  display: none;
}
