.webiny-pb-layout-block-container {
}
.webiny-pb-media-query--desktop .webiny-pb-layout-block-container {
  @include background(--desktop);
}
.webiny-pb-media-query--tablet .webiny-pb-layout-block-container {
  @include background(--tablet);
}
.webiny-pb-media-query--mobile-landscape .webiny-pb-layout-block-container {
  @include background(--mobile-landscape);
  background-size: cover !important;
}
.webiny-pb-media-query--mobile-portrait .webiny-pb-layout-block-container {
  @include background(--mobile-portrait);
  background-size: cover !important;
}

.webiny-pb-layout-block {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-self: flex-start;
  background: none !important;

  &:not(.container) {
    width: 100%;
  }
}
.webiny-pb-media-query--mobile-landscape .webiny-pb-layout-block {
  width: 100% !important;
  // flex: 0 0 auto !important; // removed because Content #2
}
.webiny-pb-media-query--mobile-portrait .webiny-pb-layout-block {
  width: 100% !important;
  // flex: 0 0 auto !important; // removed because Content #2
}

.webiny-pb-layout-grid {
  width: 100%;
  display: flex;
  flex-direction: row;

  &-cell {
    @for $current from 1 to 13 {
      &-#{$current} {
        width: percentage((1 / 12) * $current);
      }
    }

    & img {
      width: auto;
      height: auto;
    }
  }
}

.webiny-pb-media-query--desktop .webiny-pb-layout-grid {
  flex-direction: row;
  // flex: 100% 1; // removed because Content #2
}
.webiny-pb-media-query--tablet .webiny-pb-layout-grid {
  flex-direction: row;
  // flex: 100% 1; // removed because Content #2
}
.webiny-pb-media-query--mobile-landscape .webiny-pb-layout-grid {
  flex-direction: column;
  max-width: 100% !important;
  //width: 100% !important; // added for Content #6
  //width: auto !important; // set to auto for CTA #9
  flex: 0 auto !important;

  &-cell {
    flex-grow: 1;

    @for $current from 1 to 13 {
      &-#{$current} {
        width: 100%;
      }
    }

    & img {
      width: auto;
      height: auto;
    }
  }
}
.webiny-pb-media-query--mobile-portrait .webiny-pb-layout-grid {
  flex-direction: column;
  max-width: 100% !important;
  //width: 100% !important; // added for Content #6
  //width: auto !important; // set to auto for CTA #9
  flex: 0 auto !important;

  &-cell {
    flex-grow: 1;

    @for $current from 1 to 13 {
      &-#{$current} {
        width: 100%;
      }
    }

    & img {
      width: auto;
      height: auto;
    }
  }
}

.webiny-pb-layout-row {
  position: relative;
  box-sizing: border-box;
  flex-direction: row;
  width: 100%;
  // align-self: stretch;

  @include grid;

  // Tablets use desktop gap setting
  --tablet-gap: var(--desktop-gap);

  // Desktop & Tablet use flex layout
  .webiny-pb-media-query--tablet &,
  .webiny-pb-media-query--desktop & {
    display: flex;
  }

  // Option: Display tablets like mobile
  .webiny-pb-media-query--tablet & {
    &--like-mobile {
      display: grid;
    }
  }

  // Option: Display mobile like desktop
  .webiny-pb-media-query--mobile-landscape &,
  .webiny-pb-media-query--mobile-portrait & {
    &--like-desktop {
      display: flex;
    }
  }

  &--carousel .slider {
    min-width: 10px;
  }
}

.webiny-pb-layout-column {
  box-sizing: border-box;
  text-align: left;
  display: flex;
  position: relative;
  flex: auto 0;
  flex-direction: column;
}

.webiny-pb-page-element-container {
  max-width: 100%;
}

.webiny-pb-media-query--mobile-portrait,
.webiny-pb-media-query--mobile-landscape {
  .webiny-pb-layout {
    &-block-container,
    &-block:not(.container),
    &-row:not(.webiny-pb-layout-row--carousel, .webiny-pb-layout-row--like-desktop) {
      & > .webiny-pb-layout-column,
      & > .row-column-container {
        width: auto !important;
        max-width: 100% !important;
      }
    }
  }
}

.webiny-cms-list {
  @include grid;

  // Default gap values
  --desktop-gap: 15px;
  --tablet-gap: 15px;
  --mobile-gap: 10px;
}
