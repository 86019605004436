.social-buttons {
  .modal-dialog &.list-group {
    margin-bottom: 0;

    .list-group-item {
      border-radius: 0;
    }
  }

  .list-group-item {
    display: flex !important;
    justify-content: space-around;
  }

  .social-button {
    line-height: 1.625;
    @include mobile {
      flex-direction: column;
      min-width: 90px;
      margin-top: 5px;

      button {
        margin-right: 0;
      }
    }
  }
}

.social-button {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  min-width: 130px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  &.comment-button.clickable,
  &.share-button {
    cursor: pointer;
  }

  button {
    margin-right: 5px;
    padding: 1px 10px;
  }

  .popover {
    display: block;

    &.bottom {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
