@mixin inputBackground($path) {
  background-position: right center;
  background-repeat: no-repeat;
  background-image: url($path);
}

.form-group .form-group:last-child {
  margin-bottom: 0;
}

.form-control {
  font-style: italic;
  margin: 5px 0;
  box-sizing: border-box;

  // validation
  &.validating,
  .validating &,
  .submitting & {
    @include inputBackground("/img/validating.gif");
  }

  &.datepicker {
    @include inputBackground("/img/datepicker.png");
  }
}

input[type="text"],
input[type="email"],
input[type="date"],
input[type="password"] {
  &.form-control.valid,
  .valid &.form-control {
    color: #555555;
    background-color: #e3e5bd;
    @include inputBackground("/img/valid_checked.png");
  }
}

input[type="date"].form-control {
  &.valid,
  .valid & {
    background-position-x: 90%;
  }
}

button,
input {
  &.btn.disabled,
  &[disabled].btn {
    &,
    &:hover {
      background-color: #ccc;
      border-color: #ccc;
    }
  }
}

.form-check {
  .checkbox,
  .radio {
    display: inline-block;
    height: 33px;
    min-width: 33px;
    line-height: 33px;
    padding-left: 33px;
    vertical-align: middle;
    margin: 0;
    cursor: pointer;
    background-repeat: no-repeat;

    &.checkbox-autoheight,
    &.radio-autoheight {
      height: auto;
    }

    input {
      display: none;
    }

    label {
      padding-left: 7px;
    }

    .field-radio-group & {
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    // with hover effect
    .inlineCheck {
      width: auto;
      height: auto;
      position: relative;
      border-radius: 0.27em;
      background: white;
      padding: 1em 0.5em;

      img {
        margin: -1em -0.5em;
        vertical-align: middle;
      }

      .overlay {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0.25em;
        background-position: center center;
        background-repeat: no-repeat;
      }

      &.big .overlay {
        &.hover {
          background-image: url("/img/big_inline_check_hover.png");
        }

        &.active {
          background-image: url("/img/big_inline_check.png");
        }
      }

      &.small .overlay {
        &.hover {
          background-image: url("/img/small_inline_check_hover.png");
        }

        &.active {
          background-image: url("/img/small_inline_check.png");
        }
      }

      &.tiny {
        padding: 0;

        img {
          max-width: 80px;
          margin: 0;
          border-radius: 0.2em;
        }
      }
    }
  }

  .checkbox {
    background-image: url("/img/checkbox_default.png");

    &.selected {
      background-image: url("/img/checkbox_selected.png");
    }

    &.disabled {
      background-image: url("/img/checkbox_disabled.png");
      cursor: default;
    }
  }

  .radio {
    background-image: url("/img/radiobutton_default.png");

    &.selected {
      background-image: url("/img/radiobutton_selected.png");
    }

    &.disabled {
      background-image: url("/img/radiobutton_disabled.png");
      cursor: default;
    }

    & + .radio {
      margin-left: 10px;
    }
  }

  .checkboxes .checkbox {
    display: block;
    margin-top: 5px;
  }

  .tinyInlineCheck {
    label {
      width: auto !important;
      font-size: 16px;
    }

    .validator {
      display: inline-block;
    }
  }

  // some style to allow nice labels
  .inlineCheckLabelContainer {
    display: inline-block;
    vertical-align: baseline;
    font-size: 150%;
    margin: 0 0.3em;

    > * {
      display: block;
    }

    .inlineCheck {
      display: inline-block;
    }

    label {
      text-align: center;
      width: 10em;
      margin: 0.5em auto;
    }
  }
}

// states
.state {
  padding: 1em 0;
  display: none;

  &.success,
  &.error {
    display: block;
  }

  .success,
  .error {
    display: none;
    border: 1px solid;
    border-radius: 0.4em;
    padding: 0.7em 0.7em 0.7em 3em;
  }

  &.success .success {
    display: block;
    border-color: #9da60a;
    background: transparent url("/img/checkbox_selected.png") no-repeat scroll
      0.2em 0.2em;
  }

  &.error .error {
    display: block;
    border-color: #ed1c24;
    background: transparent url("/img/error.png") no-repeat scroll 0.2em 0.2em;
  }
}

// footer
.formFooter {
  padding-top: 0.4em;
  margin-top: 0.4em;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  & + .formFooter {
    border-top: 1px solid #666;
  }

  > a:not(.button),
  > span:not(.button) {
    padding-top: 0.47em;
    padding-bottom: 0.47em;
    margin: 0;
  }
}
