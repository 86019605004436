.user-card {
  padding: 20px;
  background: $brand-primary url(/img/navmenu-bg.jpg) no-repeat;
  background-size: cover;
  color: white !important;

  img {
    margin-right: 5px;
  }
}
