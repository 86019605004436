@import "~mibaby-frontend/styles/variables.scss";

// Typography
@import url("/fonts/index.css");
$webiny-pb-theme-typography-primary-font-family: $mibaby-typography-primary-font-family !default;
$webiny-pb-theme-typography-secondary-font-family: $mibaby-typography-secondary-font-family !default;
$webiny-pb-theme-typography-serif-font-family: $mibaby-typography-serif-font-family !default;
$webiny-pb-theme-typography-cursive-font-family: $mibaby-typography-cursive-font-family !default;
$webiny-pb-theme-typography-opensans-font-family: $mibaby-typography-opensans-font-family !default;
$baseFontSize: $mibaby-typography-base-font-size;
$baseLineHeight: $mibaby-typography-base-line-height;

// Colors
$webiny-pb-theme-primary: $mibaby-primary !default;
$webiny-pb-theme-primary-light: $mibaby-primary-light !default;
$webiny-pb-theme-secondary: $mibaby-secondary !default;
$webiny-pb-theme-secondary-light: $mibaby-secondary-light !default;
$webiny-pb-theme-red: $mibaby-red !default;
$webiny-pb-theme-red-light: $mibaby-red-light !default;
$webiny-pb-theme-cyan: $mibaby-cyan !default;
$webiny-pb-theme-cyan-light: $mibaby-cyan-light !default;

$webiny-pb-theme-background: $mibaby-background !default;
$webiny-pb-theme-surface: $mibaby-surface !default;
$webiny-pb-theme-cta: $mibaby-cta !default;
$webiny-pb-theme-grey: $mibaby-grey !default;
$webiny-pb-theme-lightgrey: $mibaby-lightgrey !default;

// Background colors
$webiny-pb-theme-on-primary: $mibaby-on-primary !default;
$webiny-pb-theme-on-primary-light: $mibaby-on-primary-light !default;
$webiny-pb-theme-on-secondary: $mibaby-on-secondary !default;
$webiny-pb-theme-on-secondary-light: $mibaby-on-secondary-light !default;
$webiny-pb-theme-on-red: $mibaby-on-red !default;
$webiny-pb-theme-on-red-light: $mibaby-on-red-light !default;
$webiny-pb-theme-on-cyan: $mibaby-on-cyan !default;
$webiny-pb-theme-on-cyan-light: $mibaby-on-cyan-light !default;

$webiny-pb-theme-on-background: $mibaby-on-surface !default;
$webiny-pb-theme-on-surface: $mibaby-on-surface !default;
$webiny-pb-theme-on-cta: $mibaby-on-cta !default;
$webiny-pb-theme-on-grey: $mibaby-on-grey !default;

// Text colors
$webiny-pb-theme-text-primary: $mibaby-text-primary !default;
$webiny-pb-theme-text-secondary: $mibaby-text-secondary !default;

$webiny-pb-theme-color-border: #e8ebee !default;

// dark theme
$webiny-theme-dark-surface: #242424 !default;
$webiny-theme-dark-text-primary-on-background: rgba(255, 255, 255, 0.87) !default;

// media queries
$webiny-pb-theme-mobile-width: $mibaby-mobile-width;
$webiny-pb-theme-tablet-width: $mibaby-tablet-width;
$webiny-pb-theme-desktop-width: $mibaby-desktop-width;

// elements
$webiny-pb-theme-border-radius: 2px;

// bootstrap
$icon-font-path: "../../fonts/bootstrap/";
$brand-primary: $mibaby-primary;
$brand-secondary: $mibaby-secondary;
$brand-success: $mibaby-success;
$brand-warning: $mibaby-warning;
$brand-danger: $mibaby-danger;
$brand-info: $mibaby-info;
$text-muted: #777;
$state-danger-text: $brand-danger;
$font-family-sans-serif: $mibaby-typography-primary-font-family;
$font-size-base: $baseFontSize + 0px;
$line-height-base: $baseLineHeight;
$grid-gutter-width: 20px;
$screen-xs: $mibaby-mobile-width;
$screen-sm: $mibaby-tablet-width;
$screen-md: $mibaby-desktop-width;
$screen-lg: $mibaby-widescreen-width;
$container-tablet: $screen-sm - 40px;
$container-desktop: $screen-md - 60px;
$container-large-desktop: $screen-lg - 80px;
$padding-base-vertical: 10px;
$padding-base-horizontal: 15px;
$modal-inner-padding: 20px;
$modal-inner-padding-xs: 10px;
$input-bg: #f5f6f7;
$input-color: #777;
$btn-primary-border: $brand-primary;

body {
  // Typography
  --webiny-theme-typography-primary-font-family: #{$webiny-pb-theme-typography-primary-font-family};
  --webiny-theme-typography-secondary-font-family: #{$webiny-pb-theme-typography-secondary-font-family};
  --webiny-theme-typography-serif-font-family: #{$webiny-pb-theme-typography-serif-font-family};
  --webiny-theme-typography-cursive-font-family: #{$webiny-pb-theme-typography-cursive-font-family};
  --webiny-theme-typography-opensans-font-family: #{$webiny-pb-theme-typography-opensans-font-family};

  // Colors
  --webiny-theme-color-primary: #{$webiny-pb-theme-primary};
  --webiny-theme-color-primary-light: #{$webiny-pb-theme-primary-light};
  --webiny-theme-color-secondary: #{$webiny-pb-theme-secondary};
  --webiny-theme-color-secondary-light: #{$webiny-pb-theme-secondary-light};
  --webiny-theme-color-red: #{$webiny-pb-theme-red};
  --webiny-theme-color-red-light: #{$webiny-pb-theme-red-light};
  --webiny-theme-color-cyan: #{$webiny-pb-theme-cyan};
  --webiny-theme-color-cyan-light: #{$webiny-pb-theme-cyan-light};

  --webiny-theme-color-background: #{$webiny-pb-theme-background};
  --webiny-theme-color-surface: #{$webiny-pb-theme-surface};
  --webiny-theme-color-cta: #{$webiny-pb-theme-cta};
  --webiny-theme-color-grey: #{$webiny-pb-theme-grey};
  --webiny-theme-color-lightgrey: #{$webiny-pb-theme-lightgrey};
  --webiny-theme-color-black: black;
  --webiny-theme-color-white: white;

  // TODO: Legacy
  --webiny-theme-color-green-b: #{$webiny-pb-theme-primary};
  --webiny-theme-color-green-c: #{$webiny-pb-theme-primary-light};
  --webiny-theme-color-green-d: #{$webiny-pb-theme-primary};
  --webiny-theme-color-green-e: #{$webiny-pb-theme-primary};
  --webiny-theme-color-grey-b: #{$webiny-pb-theme-grey};
  --webiny-theme-color-grey-c: #{$webiny-pb-theme-grey};
  --webiny-theme-color-grey-d: #{$webiny-pb-theme-grey};
  --webiny-theme-color-purple-b: #{$webiny-pb-theme-secondary-light};
  --webiny-theme-color-purple-c: #{$webiny-pb-theme-secondary};
  --webiny-theme-color-purple-d: #{$webiny-pb-theme-secondary};

  // Background colors
  --webiny-theme-color-on-primary: #{$webiny-pb-theme-on-primary};
  --webiny-theme-color-on-primary-light: #{$webiny-pb-theme-on-primary-light};
  --webiny-theme-color-on-secondary: #{$webiny-pb-theme-on-secondary};
  --webiny-theme-color-on-secondary-light: #{$webiny-pb-theme-on-secondary-light};
  --webiny-theme-color-on-red: #{$webiny-pb-theme-on-red};
  --webiny-theme-color-on-red-light: #{$webiny-pb-theme-on-red-light};
  --webiny-theme-color-on-cyan: #{$webiny-pb-theme-on-cyan};
  --webiny-theme-color-on-cyan-light: #{$webiny-pb-theme-on-cyan-light};

  --webiny-theme-color-on-background: #{$webiny-pb-theme-on-background};
  --webiny-theme-color-on-surface: #{$webiny-pb-theme-on-surface};
  --webiny-theme-color-on-cta: #{$webiny-pb-theme-on-cta};
  --webiny-theme-color-on-grey: #{$webiny-pb-theme-on-grey};
  --webiny-theme-color-on-black: white;
  --webiny-theme-color-on-white: #{$webiny-pb-theme-on-surface};

  // Text colors
  --webiny-theme-color-text-primary: #{$webiny-pb-theme-text-primary};
  --webiny-theme-color-text-secondary: #{$webiny-pb-theme-text-secondary};
  --webiny-theme-color-text-strong: #{$mibaby-text-strong};
  --webiny-theme-color-text-form: #{$mibaby-text-form};

  --webiny-theme-color-border: #{$webiny-pb-theme-color-border};
  --webiny-theme-border-radius: #{$webiny-pb-theme-border-radius};
}

body.dark-theme {
  --webiny-theme-color-text-primary: #{$webiny-theme-dark-text-primary-on-background};
  --webiny-theme-color-surface: #{$webiny-theme-dark-surface};
}
