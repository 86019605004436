.coupon-code {
  text-align: center;
  color:#9BA720;
  margin-top: 20px;
}

.coupon-code h3 {
  border: 1px solid #9BA720;
  border-radius: 4px;
  padding: 20px;
}

.coupon-code h4 {
  font-weight: bold;
}

.coupon-code > button {
  padding: 10px;
  font-weight: bold;
}

.coupon-details {
  padding: 0 20px 10px 20px;
  line-height: 30px;
}

.coupon-usage {
  color: #b4b4b4;
  font-size: 10pt;
  line-height: 22px;
  text-align: justify;
}

.coupon-image {
  padding-bottom: 10px;
}

.coupon-image img {
  object-fit: contain;
  max-width: 100%;
  max-height: 400px;
}
