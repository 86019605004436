@import "media-queries";
@import "border";
@import "background";
@import "pb-list-element";
@import "type";
@import "layout";

@mixin unreset {
  b,
  strong {
    font-weight: bold;
  }
  i {
    font-style: italic;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  ul,
  ol {
    padding-inline-start: 40px;
  }
  small {
    font-size: smaller;
  }
  sub {
    vertical-align: sub;
  }
  sup {
    vertical-align: super;
  }
}
