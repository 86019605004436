.goto-top-button {
  position: fixed;
  bottom: 0;
  right: 25px;
  background: $webiny-pb-theme-primary;
  color: $webiny-pb-theme-on-primary;
  border: 1px solid lighten($webiny-pb-theme-primary, 10%);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 52px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  z-index: 1001;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-in-out;

  &--shown {
    opacity: 1;
    visibility: visible;
    bottom: 50px;
  }

  @include mobile {
    right: 10px;

    &--shown {
      bottom: 10px;
    }
  }
}
