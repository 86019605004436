@mixin grid {
  --desktop-grid-template-columns: 4;
  --tablet-grid-template-columns: 2;
  --mobile-grid-template-columns: 1;
  --desktop-gap: 0;
  --tablet-gap: 0;
  --mobile-gap: 0;

  --grid-min-column-width: auto;
  --grid-template-columns: var(--desktop-grid-template-columns);
  --gap: var(--desktop-gap);

  display: grid;
  grid-template-columns: repeat(
    var(--grid-template-columns),
    minmax(var(--grid-min-column-width, auto), 1fr)
  );
  gap: var(--gap);

  .webiny-pb-media-query--tablet & {
    --grid-template-columns: var(--tablet-grid-template-columns);
    --gap: var(--tablet-gap);
  }

  .webiny-pb-media-query--mobile-landscape &,
  .webiny-pb-media-query--mobile-portrait & {
    --grid-template-columns: var(--mobile-grid-template-columns);
    --gap: var(--mobile-gap);
  }

  > * {
    min-width: 0;
  }
}
