.quiz-modal {
  font-family: $font-family-sans-serif;

  .modal-body {
    background-color: $mibaby-background;
  }
}

.quiz-modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    flex-grow: 1;
    padding: 0;
    margin: 0 10px;
    text-align: center;
    color: white;
  }
  span {
    background: white;
    color: $brand-primary;
    font-weight: bold;
    min-width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 100%;
    line-height: 50px;
    font-size: 30px;
  }
  button {
    color: white;
    line-height: 1em;
  }
}

.quiz-question-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.quiz-question-list-item .panel {
  position: relative;
  z-index: 1;
  background: white;
  box-shadow: 1px 1px 3px #ddd;
  border-radius: 4px !important;
  overflow: hidden;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: stretch;

  img {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
  }
}

.quiz-question-list-item.selected .panel {
  border: 2px solid $brand-primary;
  box-shadow: 0px 0px 5px $brand-primary;
}

.quiz-question-list-item.selected.has-error .panel {
  border: 2px solid $brand-danger;
  box-shadow: 0px 0px 5px $brand-danger;
}

.modal-quiz-tip {
  .modal-quiz-info,
  .modal-quiz-error {
    text-align: center;
    line-height: 1.5em;
    i {
      font-size: 30px;
    }
    color: white;
    padding: 20px 10px;
  }

  .modal-quiz-error {
    background-color: $brand-danger;
  }

  .modal-quiz-info {
    background-color: $brand-primary;
  }

  .quiz-tip-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .quiz-tip-list-number {
        font-weight: bold;
        line-height: 50px;
        border-radius: 50px;
        min-height: 50px;
        min-width: 50px;
        text-align: center;
        background-color: var(--webiny-theme-color-background, $mibaby-background);
        margin-right: 10px;
      }

      span {
        font-weight: bold;
      }
    }
  }
}
