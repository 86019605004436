#cc_div {
  --cc-btn-primary-bg: #{$mibaby-secondary};

  font-family: $mibaby-typography-primary-font-family;
  color: $mibaby-text-primary;

  #cm {
    --cc-bg: #{$mibaby-primary};

    color: white;
  }
}
