@mixin text-variant($color) {
  color: $color;
  &:hover,
  &:focus {
    color: darken($color, 10%);
  }
}

@mixin text-danger() {
  @include text-variant($state-danger-text);
}

@mixin text-muted() {
  color: $text-muted;
}

@mixin placeholder-color($color: $mibaby-text-form-placeholder) {
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
}
