// make images responsive
picture,
img {
  max-width: 100%;
}

// Make images responsive on mobile
.webiny-pb-media-query--mobile-landscape,
.webiny-pb-media-query--mobile-portrait {
  picture,
  img {
    max-width: 100%;
  }

  // make images center align on mobile
  .webiny-pb-page-element-image {
    display: block;
    text-align: center !important;
  }
}

.webiny-pb-page-element-image {
  width: 100%;
  line-height: 0;
  overflow: hidden;
  box-sizing: border-box;

  picture,
  img {
    max-width: 100%;
  }
}
