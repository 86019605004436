.mb-cms-element-teaser {
  position: relative;
  background: white;
  box-shadow: 1px 1px 3px #ddd;
  border-radius: 4px !important;

  .webiny-pb-typography-body {
    margin-top: 0;
  }
}
