// Typography
$mibaby-typography-primary-font-family: Lexend Deca Light, sans-serif;
$mibaby-typography-secondary-font-family: Lexend Deca SemiBold, sans-serif;
$mibaby-typography-cursive-font-family: Calton Elegance, cursive;
$mibaby-typography-serif-font-family: Lexend Deca Light, sans-serif;
$mibaby-typography-opensans-font-family: Lexend Deca Light, sans-serif;
$mibaby-typography-base-font-size: 16;
$mibaby-typography-base-line-height: 1.42;

// Colors
$mibaby-primary: #66b08b;
$mibaby-primary-light: #e3e2bb;
$mibaby-secondary: #5e4b93;
$mibaby-secondary-light: #c7a6cf;
$mibaby-red: #d8545f;
$mibaby-red-light: #f4cdab;
$mibaby-cyan: #5fb6c4;
$mibaby-cyan-light: #d8e1e9;

$mibaby-background: white;
$mibaby-surface: white;
$mibaby-cta: #f51017;
$mibaby-grey: #cccccc;
$mibaby-lightgrey: #efefef;

// Background colors
$mibaby-on-primary: white;
$mibaby-on-primary-light: black;
$mibaby-on-secondary: white;
$mibaby-on-secondary-light: black;
$mibaby-on-red: white;
$mibaby-on-red-light: black;
$mibaby-on-cyan: white;
$mibaby-on-cyan-light: black;

$mibaby-on-background: black;
$mibaby-on-surface: black;
$mibaby-on-cta: white;
$mibaby-on-grey: white;

// Text colors
$mibaby-text-primary: #1d1d1b;
$mibaby-text-secondary: #616161;
$mibaby-text-strong: #1d1d1b;
$mibaby-text-form: #1d1d1b;
$mibaby-text-form-placeholder: #999999;

// Utility colors
$mibaby-success: #e3e5bd;
$mibaby-warning: #f0ad4e;
$mibaby-danger: #cc1417;
$mibaby-info: #5bc0de;

// Responsive breakpoints
$mibaby-mobile-width: 480px;
$mibaby-tablet-width: 768px;
$mibaby-desktop-width: 924px;
$mibaby-widescreen-width: 1080px;
$mibaby-header-width: 900px;
