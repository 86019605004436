.sq60 {
  height: 60px !important;
  width: 60px !important;
}

.sq100 {
  height: 100px !important;
  width: 100px !important;
}

.img-inline {
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
  & > a,
  & > img {
    margin-left: 3px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.img-block {
  display: block;
}

.img-responsive {
  max-width: 100%;
}

a,
button,
.btn {
  &.go_more::after {
    content: " »";
  }
}

.img-circle {
  border-radius: 50%;
}

.hide-on-mobile,
.show-on-desktop-and-tablet {
  @include mobile {
    display: none !important;
  }
}

.hide-on-desktop-and-tablet,
.show-on-mobile {
  @include desktop-and-tablet {
    display: none !important;
  }
}

.unreset {
  @include unreset;
}
