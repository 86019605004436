@mixin border($property) {
  border-top-width: var(#{$property}-border-top-width);
  border-top-style: var(#{$property}-border-top-style);
  border-top-color: var(#{$property}-border-top-color);
  border-right-width: var(#{$property}-border-right-width);
  border-right-style: var(#{$property}-border-right-style);
  border-right-color: var(#{$property}-border-right-color);
  border-bottom-width: var(#{$property}-border-bottom-width);
  border-bottom-style: var(#{$property}-border-bottom-style);
  border-bottom-color: var(#{$property}-border-bottom-color);
  border-left-width: var(#{$property}-border-left-width);
  border-left-style: var(#{$property}-border-left-style);
  border-left-color: var(#{$property}-border-left-color);
  border-radius: var(#{$property}-border-top-left-radius) var(#{$property}-border-top-right-radius)
    var(#{$property}-border-bottom-right-radius) var(#{$property}-border-bottom-left-radius);
}

@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}
