.webiny-fb-form {
  &-field {
    &__checkbox {
      label .rich-text-editor > :last-child {
        margin-bottom: 0;
      }
    }
  }

  .webiny-pb-media-query--desktop & {
    .webiny-fb-form-layout-column {
      padding-inline: 15px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    .webiny-fb-form-field {
      &__checkbox {
        label {
          .webiny-pb-typography-body {
            font-size: 16px;
          }
        }
      }
    }

    &-recaptcha {
      padding: 0;
    }

    &-submit-button {
      padding: 0;
    }
  }

  .webiny-pb-media-query--mobile-landscape &,
  .webiny-pb-media-query--mobile-portrait & {
    .webiny-fb-form-layout-row {
      flex-direction: column;
    }

    &-field {
      &__label {
        &.webiny-pb-typography-body {
          text-align: left !important;
        }
      }

      &__helper-text {
        text-align: left !important;
      }
    }

    .webiny-fb-form-page-element-button {
      width: 100%;
    }
  }
}
