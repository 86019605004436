.editor {
  .ql-editor {
    outline: 0;

    p {
      margin-bottom: 0;
    }

    h3 {
      margin: 13px 0;
    }

    blockquote {
      padding: 0 10px;
      margin-bottom: 10px;
      font-size: initial;
      border-left-width: 3px;
    }

    img {
      max-width: 100%;
    }

    img.emoji {
      min-width: initial;
    }
  }

  .ql-clipboard {
    display: none;
  }

  .toolbar {
    background: #f7f7f7;
    display: flex;
    justify-content: space-between;

    .btn:not(.ql-active) {
      @include button-variant(
        $btn-default-color,
        $btn-default-bg,
        $btn-default-border
      );
    }

    .popover {
      display: block;
      bottom: calc(100% + 11px);
      top: initial;
      left: 50%;
      transform: translateX(-50%);

      .btn {
        width: 100%;

        &:not(first-child) {
          margin-top: 5px;
        }
      }
    }
  }

  .actions {
    margin-top: 8px;
  }

  .btn {
    font-size: 14px;
    padding: 2px 10px;

    &[value="ordered"] > .fa-list:before {
      content: $fa-var-list-ol;
    }

    .fa-blockquote:before {
      content: $fa-var-quote-right;
    }
  }

  &.composing {
    .toolbar,
    .ql-container {
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }

    .ql-container {
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .ql-editor {
      min-height: 95px;
    }

    .toolbar {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
