.vote-stats-bar {
  @include border-top-radius($panel-border-radius - 1);
  overflow: hidden;
  display: flex;

  > div {
    min-height: 5px;
    transition: all 0.5s;
  }

  &-ups {
    background-color: $brand-primary;
  }

  &-downs {
    background-color: $brand-danger;
    flex: 1;
  }
}

.vote-bar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;

  .vote-bar-count {
    padding: 0 10%;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    font-size: 0.8em;

    &.negative {
      @include text-danger();
    }
  }

  .vote-button {
    padding: 0;
    border: 0;
    background: transparent;
    outline: none;
    line-height: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 1em;
    cursor: pointer;
  }

  .vote-button > div {
    display: inline-block;
    vertical-align: top;
    transition: opacity 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275),
      margin-top 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    span {
      font-weight: bold;
    }
  }

  .vote-button-up.vote-button-state-reset,
  .vote-button-up.vote-button-state-up {
    color: $brand-primary;
  }

  .vote-button-up.vote-button-state-down,
  .vote-button-down.vote-button-state-up {
    @include text-muted();
  }

  .vote-button-down.vote-button-state-reset,
  .vote-button-down.vote-button-state-down {
    color: $brand-danger;
  }

  .vote-button .default i {
    font-size: 1em;
    vertical-align: top;
  }

  .vote-button .active {
    opacity: 0;
  }

  .vote-button .active img {
    width: 1em;
    height: 1em;
    vertical-align: top;
  }

  // state
  .vote-button.active .default {
    opacity: 0;
    margin-top: -1em;
  }
  .vote-button.active .active {
    opacity: 1;
  }
}
