.webiny-pb-section-footer {
  background-color: white;
  box-sizing: border-box;
  text-align: center;

  h5 {
    margin-bottom: 17px;
  }

  p {
    color: #999;
    margin-top: 20px;
  }

  li {
    padding: 7px 0;
  }

  a:not(:hover) {
    text-decoration: none;
  }

  > * {
    margin-top: 15px;
  }
}
