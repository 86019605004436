.wysiwyg-font-size-smaller {
  font-size: smaller;
}

.wysiwyg-font-size-larger {
  font-size: larger;
}

.wysiwyg-font-size-xx-large {
  font-size: xx-large;
}

.wysiwyg-font-size-x-large {
  font-size: x-large;
}

.wysiwyg-font-size-large {
  font-size: large;
}

.wysiwyg-font-size-medium {
  font-size: medium;
}

.wysiwyg-font-size-small {
  font-size: small;
}

.wysiwyg-font-size-x-small {
  font-size: x-small;
}

.wysiwyg-font-size-xx-small {
  font-size: xx-small;
}

.wysiwyg-color-black {
  color: black;
}

.wysiwyg-color-silver {
  color: silver;
}

.wysiwyg-color-gray {
  color: gray;
}

.wysiwyg-color-white {
  color: white;
}

.wysiwyg-color-maroon {
  color: maroon;
}

.wysiwyg-color-red {
  color: red;
}

.wysiwyg-color-purple {
  color: purple;
}

.wysiwyg-color-fuchsia {
  color: fuchsia;
}

.wysiwyg-color-green {
  color: green;
}

.wysiwyg-color-lime {
  color: lime;
}

.wysiwyg-color-olive {
  color: olive;
}

.wysiwyg-color-yellow {
  color: yellow;
}

.wysiwyg-color-navy {
  color: navy;
}

.wysiwyg-color-blue {
  color: blue;
}

.wysiwyg-color-teal {
  color: teal;
}

.wysiwyg-color-aqua {
  color: aqua;
}

.wysiwyg-text-align-right {
  text-align: right;
}

.wysiwyg-text-align-center {
  text-align: center;
}

.wysiwyg-text-align-left {
  text-align: left;
}

.wysiwyg-text-align-justify {
  text-align: justify;
}

.wysiwyg-float-left {
  float: left;
  margin: 0 8px 8px 0;
}

.wysiwyg-float-right {
  float: right;
  margin: 0 0 8px 8px;
}

.wysiwyg-clear-right {
  clear: right;
}

.wysiwyg-clear-left {
  clear: left;
}
