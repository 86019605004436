.cms-contestWinner {
  overflow: hidden;

  &__title,
  &__date,
  &__name {
    margin: 12px 0;
  }

  &__button {
    margin-top: 17px;
  }
}
