.webiny-pb-section-header,
.webiny-pb-section-offcanvas {
  .navmenu {
    border: 0;
  }

  li.nav-item {
    &.with-color {
      & a.nav-link:not(:hover) i,
      &.with-bg-color a.nav-link i {
        color: var(--menu-item-color) !important;
      }
    }

    &.with-text-color {
      a.nav-link:not(:hover) span,
      &.with-bg-color a.nav-link span {
        color: var(--menu-item-text-color) !important;
      }
    }

    &.with-badge a.nav-link span {
      position: relative;

      &::after {
        content: var(--menu-item-badge-text);
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        transform: translate(50%, -50%) rotate(45deg) translate(0, -5px);
        background: var(--menu-item-badge-background-color);
        font-weight: bold;
        color: var(--menu-item-badge-color);
        font-size: 9px;
        padding: 2px 3px;
        font-family: Open Sans, sans-serif;
      }
    }
  }

  a.nav-link {
    display: flex;
    gap: 5px;
    font-size: 14px;
    line-height: 14px;
    text-decoration: none !important;
    align-items: center;

    &:hover,
    &:focus {
      background: initial;
    }

    i {
      font-size: 25px;
    }

    &:not(:hover) span {
      color: black;
    }
  }
}

.webiny-pb-section-header {
  background-color: white;
  box-shadow: 0 0 3px #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  position: sticky;
  top: 0;
  z-index: 1000;

  &--desktop,
  &--mobile {
    display: flex;
  }

  &__logo {
    svg {
      width: auto;
    }
  }

  &__hamburger {
    width: 48px;
  }

  &__nav {
    display: flex;
    margin: 0;

    a.nav-link {
      text-decoration: none;
      padding: 10px;
    }
  }

  @media (max-width: calc($mibaby-header-width - 1px)) {
    &--desktop {
      display: none !important;
    }

    &--mobile {
      align-items: center;
    }

    &__logo {
      margin: 8px 5px 0;

      svg {
        height: 28px;
      }
    }

    &__nav {
      flex: 1;
      justify-content: right;

      a.nav-link {
        span {
          display: none;
        }
      }
    }
  }

  @media (min-width: $mibaby-header-width) {
    &--mobile {
      display: none !important;
    }

    &--desktop {
      height: 75px;
      align-items: flex-end;
      max-width: 1000px;
      margin-inline: auto;
      padding-bottom: 5px;
    }

    &__logo {
      svg {
        width: 115px;
        height: 60px;
      }
    }

    &__nav {
      flex: 1 0 calc(50% - 115px / 2);
      padding-inline: 20px;
      justify-content: space-between;

      li.nav-item {
        display: flex;
        justify-content: center;
        align-items: flex-end;
      }

      a.nav-link {
        padding: 10px 5px;
        font-weight: bold;

        i.mbicon {
          font-size: 30px;
        }
      }
    }
  }

  &--small {
    display: flex;
    justify-content: center;
    height: 26px;
    margin: 10px 15px;
    position: relative;

    &__logo svg {
      max-height: 100%;
    }

    &__user-action {
      position: absolute;
      right: 0;
      top: 50%;
      font-size: 14px;
      margin-top: -7px;
    }
  }
}

.webiny-pb-section-offcanvas {
  position: fixed;
  width: 300px;
  height: 100vh;
  background: white;
  z-index: 1001;
  border: 0 solid #e7e7e7;

  &--left {
    transform: translateX(-100%);
    border-right-width: 1px;
  }

  &--right {
    margin-left: 100vw;
    border-left-width: 1px;
  }

  .user-menu {
    .login-form {
      padding: 20px;
    }

    // TODO: We need a new logout-icon
    .glyphicon-off {
      font-size: 21px;
      padding: 2px;
    }
  }
}

body {
  left: 0;
  position: relative;
  transition: left 200ms ease-in-out;
}

body.offcanvas {
  &--left {
    position: relative;
    overflow: hidden;
    left: 300px;
  }

  &--right {
    position: relative;
    overflow: hidden;
    left: -300px;
  }
}

.top-bar-promotion {
  text-align: center;

  > a,
  div {
    display: block;
    text-decoration: none;
    padding: 8px 0;
    text-align: center;
    line-height: 18px !important;
    font-size: 14px;
    font-weight: bold;
  }
}
