.webiny-pb-base-page-element-cms-poll {
  border-radius: 6px;
  padding: 0 !important;
  overflow: hidden;
}

.poll {
  --poll-color-question-background: var(--webiny-theme-color-primary);
  --poll-color-question-text: var(--webiny-theme-color-white);
  --poll-color-option-background: var(--webiny-theme-color-lightgrey);
  --poll-color-selected: var(--webiny-theme-color-cyan);
  --poll-color-option-text: var(--webiny-theme-color-text-primary);
  --poll-color-votes-bar: var(--webiny-theme-color-primary-light);
  --poll-color-votes-bar-text: var(--webiny-theme-color-text-primary);

  &-question {
    --typography-color: var(--poll-color-question-text);
    color: var(--typography-color);
    background: var(--poll-color-question-background);
    text-align: center;
    margin: 0;
    padding: 15px;
  }

  &-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
  }

  &-option {
    --typography-color: var(--poll-color-option-text);
    color: var(--typography-color);
    background: var(--poll-color-option-background);
    border-radius: 6px;
    overflow: hidden;

    &__selected,
    &__vote:hover {
      box-shadow: 0 0 0 3px var(--poll-color-selected);
    }

    &__vote {
      cursor: pointer;
    }
  }

  &-option-answer,
  &-option-votes {
    padding-inline: 15px;
  }

  &-option-answer {
    padding-block: 8px;
  }

  &-option-votes {
    color: var(--poll-color-votes-bar-text);
    height: 30px;
    display: flex;
    justify-content: space-between;
    padding-block: 5px;
    background-image: linear-gradient(
      90deg,
      var(--poll-color-votes-bar) calc(var(--poll-option-votes, 0) * 1%),
      transparent calc(var(--poll-option-votes, 0) * 1%)
    );
    transition: background 1s linear;
  }

  & > &-completion-content {
    margin: 15px;
  }

  &-question,
  &-option {
    .rich-text-editor > .webiny-pb-typography-body:last-child {
      margin-bottom: 0;
    }
  }
}
