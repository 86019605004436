.btn {
  border-radius: 999px; // Just needs to be a very high value

  &-secondary {
    background-color: var(--webiny-theme-color-secondary, $mibaby-secondary);
    color: $mibaby-on-secondary;
  }

  &-cta {
    background-color: var(--webiny-theme-color-cta, $mibaby-cta);
    color: var(--webiny-theme-color-on-cta, $mibaby-on-cta);
  }
}
