@include mobile {
  .list-group {
    margin-bottom: $padding-small-vertical;
  }
  .list-group-item {
    padding: 5px 15px;
  }
  .container {
    width: 100%;
  }
}

.btn {
  line-height: 1.625;
}
