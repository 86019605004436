.webiny-fb-form {
  width: 100%;
  padding: 0 0 15px;
  box-sizing: border-box;
  background-color: white;

  @include mobile {
    padding: 5px 0;
  }

  @include desktop {
    padding: 0 15px 15px;
  }

  .webiny-fb-form-layout-row,
  .webiny-fb-form-layout-column {
    display: flex;
    width: 100%;
    padding: 0;
  }

  .webiny-fb-form-layout-column {
    padding: 5px 15px;

    @include desktop {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .webiny-fb-form-layout-no-full-width {
    width: auto;
  }

  &-tos {
    margin: 0;
  }

  &-recaptcha {
    margin: 0 0 20px 0;
    padding: 0 15px;

    @include desktop {
      padding: 0;
    }
  }

  &-step-button {
    display: flex;
    justify-content: flex-end;
    padding: 0 15px;

    & > .webiny-fb-form-page-element-button {
      width: initial !important;
      margin-left: 10px;
    }
  }

  &-submit-button,
  &-upload-button {
    padding: 0 15px;

    @include desktop {
      padding: 0;
    }
  }

  &-form__success-message {
    text-align: center;
  }

  &-form__error-message {
    color: $mibaby-danger;
  }

  &-field {
    width: 100%;
    box-sizing: border-box;
    font-size: 1rem;
    background-color: white;

    &__input,
    &__select,
    &__textarea {
      font-size: 0.9rem;
      border: 1px solid $mibaby-grey;
      background-color: var(--webiny-theme-color-background, #eaecec);
      width: 100%;
      padding: 10px 15px;
      border-radius: var(--webiny-theme-border-radius, 2px);
      box-sizing: border-box;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      font-style: italic;
      color: var(--webiny-theme-color-text-form);

      &:-moz-placeholder {
        color: $mibaby-text-form-placeholder;
      }
      &::-moz-placeholder {
        color: $mibaby-text-form-placeholder;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: $mibaby-text-form-placeholder;
      }
      &::-webkit-input-placeholder {
        color: $mibaby-text-form-placeholder;
      }

      &:focus:not([disabled]) {
        border-color: var(--webiny-theme-color-primary, #00ccb0);
        outline: none;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 204, 167, 0.6);
      }
    }

    &__select {
      height: 40px;
      -webkit-appearance: none;
      position: relative;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDBWMHoiLz48cGF0aCBkPSJNOC43MSAxMS43MWwyLjU5IDIuNTljLjM5LjM5IDEuMDIuMzkgMS40MSAwbDIuNTktMi41OWMuNjMtLjYzLjE4LTEuNzEtLjcxLTEuNzFIOS40MWMtLjg5IDAtMS4zMyAxLjA4LS43IDEuNzF6Ii8+PC9zdmc+");
      background-repeat: no-repeat;
      background-position: center right;
    }

    &__radio-group,
    &__checkbox-group {
      width: 100%;
    }

    &__radio-group {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    &__radio,
    &__checkbox {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
      margin: 5px 50px 5px 2px;
      display: flex;
      align-items: center;

      &-label {
        font-weight: inherit;
        margin-left: 10px;
        margin-top: 5px;
        line-height: 100%;
      }

      &-input {
        font-size: 1.5rem;
        margin-left: 0;
        line-height: 100%;
        background-color: var(--webiny-theme-color-background, #eaecec);
        min-width: 25px;
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        border-radius: 50%;
        -webkit-appearance: none;

        &:focus {
          border-color: var(--webiny-theme-color-secondary, #00ccb0);
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          outline: none;
        }

        &:checked {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDBWMHoiLz48cGF0aCBkPSJNMTIgMkM2LjQ4IDIgMiA2LjQ4IDIgMTJzNC40OCAxMCAxMCAxMCAxMC00LjQ4IDEwLTEwUzE3LjUyIDIgMTIgMnptMCAxOGMtNC40MiAwLTgtMy41OC04LThzMy41OC04IDgtOCA4IDMuNTggOCA4LTMuNTggOC04IDh6Ii8+PGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iNSIvPjwvc3ZnPg==");
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }

    &__radio {
      width: initial;
      margin-right: 20px;

      &-input {
        border-radius: 0;
        background-image: url(./img/radio_default.png);

        &:checked {
          background-image: url(./img/radio_selected.png);
        }
      }
    }

    &__checkbox {
      &-input {
        border-radius: var(--webiny-theme-border-radius, 2px);
        background-image: url(./img/checkbox_default.png);

        &:checked {
          background-image: url(./img/checkbox_selected.png);
        }
      }
    }

    &--file {
      label.webiny-fb-form-page-element-button {
        display: inline-block;
        width: auto;
      }
    }

    &__label {
      &.webiny-pb-typography-body {
        font-family: var(
          --webiny-theme-typography-primary-font-family,
          $mibaby-typography-primary-font-family
        );
        font-size: 1rem;
        width: 100%;
        display: inline-block;
        line-height: 100%;
        margin: 0 0 5px 1px;

        @include mobile {
          text-align: left !important;
        }
      }
    }

    &__helper-text {
      font-size: 0.8rem;
      margin-left: 2px;
      margin-top: 5px;
      color: var(--webiny-theme-color-text-secondary, #616161);

      @include mobile {
        text-align: left !important;
      }

      &--error {
        color: $mibaby-danger;
      }
    }
  }

  .webiny-fb-form-page-element-button {
    width: 100%;
  }
}

input:focus {
  outline: initial !important;
}

div[class*="-formPreviewWrapper"] {
  --webiny-theme-color-surface: #fff;
}
