.video-responsive-wrapper {
  --video-ratio: 9/16;

  .react-player .fb_iframe_widget {
    width: 100% !important;
  }

  &.thumb,
  &:not(.facebook) {
    position: relative;
    padding-top: calc(var(--video-ratio, 9/16) * 100%);
    height: 0;

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }

    .react-player .react-player__shadow {
      width: inherit !important;
      height: inherit !important;
    }
    .react-player .react-player__play-icon {
      background-image: url("/img/play.png");
      width: 100px;
      height: 100px;
      border: 0 !important;
      background-size: contain;
    }

    &:not(.has-play-icon) {
      .react-player .react-player__play-icon,
      .react-player .react-player__shadow {
        // unfortunatelly they do not let us without !important,
        // because the display setting is in the style attribute,
        // which has almost unbeatable specificity of 1000
        display: none !important;
      }
    }
  }
}
